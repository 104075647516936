import React from "react"
import Footer from "rc-footer"
import styled from "styled-components"
import "rc-footer/assets/index.css"
import { FaHome, FaServer, FaGlobeAmericas } from "react-icons/fa"

const CustomFooter = styled(Footer)`
  .rc-footer-item {
    font-family: "IBM Plex Sans";
  }
  .rc-footer-item:hover {
    cursor: pointer;
  }

  .rc-footer-bottom-container {
    color: white;
  }
`

export default function footer() {
  return (
    <div style={{ display: "block", width: "100%" }}>
      <CustomFooter
        columns={[
          {
            icon: <FaServer />,
            items: [
              { title: "Planos" },
              { title: "FAQ" },
              { title: "Suporte Técnico" },
            ],
            title: "Sistema Deli",
            openExternal: true,
          },
          {
            icon: <FaHome />,
            items: [
              {
                title: "Carreiras",
                url: "/carreiras",
                openExternal: "true",
              },
              {
                LinkComponent: () => (
                  <a
                    href="https://g.page/deli-curitiba?share"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Sede Curitiba
                  </a>
                ),
              },
              { title: "deli@delipos.com.br" },
            ],
            title: "Sobre nós",
            openExternal: true,
          },
          {
            icon: <FaGlobeAmericas />,
            items: [
              {
                LinkComponent: () => (
                  <a
                    href="https://www.facebook.com/sistemaspararestaurantes"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    facebook
                  </a>
                ),
              },
              { title: "Instagram" },
              {
                LinkComponent: () => (
                  <a
                    href="https://www.linkedin.com/company/sistemas-para-restaurantes/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    LinkedIn
                  </a>
                ),
              },
            ],
            title: "Redes",
            openExternal: true,
          },
        ]}
        bottom="Ⓒ 2020 deli"
      />
    </div>
  )
}
